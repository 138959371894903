.rich-text-list {
  @apply  list-disc;
  li {
    @apply mb-2
  }
}

.product-rich-text {
  a {
    @apply underline;
  }
}

.rich-text-numbered-list {
  li::before {
    content: counter(li);
    @apply mr-2;
    list-style: decimal-leading-zero !important;
  }
  li {
    @apply mb-2  flex;
    counter-increment: li;
  }
}
