.modal-filters-shadow {
  box-shadow: 0px -10px 24px rgba(0, 0, 0, 0.06);
}

.button-filters-shadow {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.025), 0 2px 4px -2px rgb(0 0 0 / 0.025);
}

.cart-shadow {
  box-shadow: -4px 0px 48px rgba(0, 0, 0, 0.16);
}

.navigation-quick-link-shadow {
  box-shadow: 0px 6px 8px 4px rgba(0, 0, 0, 0.16);
}