$hairline-weight: 100;
$thin-weight: 200;
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;
$xbold-weight: 800;
$black-weight: 900;

$font-primary: 'SulSans';

@font-face {
  font-family: $font-primary;
  src: url($path-fonts + 'SulSans-Regular.woff2') format('woff2'),
    url($path-fonts + 'SulSans-Regular.woff') format('woff');
  font-weight: $normal-weight;
}

@font-face {
  font-family: $font-primary;
  src: url($path-fonts + 'SulSans-Medium.woff2') format('woff2'),
    url($path-fonts + 'SulSans-Medium.woff') format('woff');
  font-weight: $medium-weight;
}