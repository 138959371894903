.markdown {
  a {
    @apply underline;
  }

  p {
    margin-bottom: 1.5rem;
  }
  
  ol {
    @apply list-decimal;
    @apply list-inside;
  }

  ul {
    @apply list-disc;
    @apply list-inside;
  }

}