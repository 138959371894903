@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'SulSans', Arial, Helvetica, sans-serif;
  }
  strong,b {
    @apply font-medium;
  }

  body.modal-open {
    @apply overflow-hidden;
  }
}

@import 'custom/variables';
@import 'custom/fonts';
@import 'custom/resets';
@import 'custom/viewport';
@import 'custom/shadows';
@import 'custom/scrollbar';
@import 'custom/rich-text';
@import 'custom/markdown';
@import 'custom/carousel';

@import 'utils/helpers';
@import 'utils/render';
@import 'utils/selection';
