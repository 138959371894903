* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

a,
button,
input,
textarea,
li {
  outline: none;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
}
a:focus,
button:focus,
input:focus,
textarea:focus,
li:focus {
  outline: none;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.no-touch {
  touch-action: none;
}
