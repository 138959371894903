.block-scrollbar {
  &::-webkit-scrollbar {
    width: 1px;
  }
   
  &::-webkit-scrollbar-track {
    
  }
   
  &::-webkit-scrollbar-thumb {
    @apply bg-sf-blue-700 outline outline-1 outline-sf-blue-300;
  }
}