.gpu {
    backface-visibility: hidden;
    transform: translateZ(0);
    will-change: transform, opacity;
}

.perspective {
    perspective: 2000px;
    perspective-origin: 50% 50%;
}

.preserve-3d {
    transform-style: preserve-3d;
}

.translate-z-100 {
    transform: translateZ(100px);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }